<template>
    <el-dialog
            :title="title"
            :visible.sync="dialogVisible"
            width="800px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="handleClose">
        <el-form size="small" ref="inputForm" :model="inputForm" :rules="rules" label-width="100px">
            <el-row :gutter="10">
                <el-col :span="12">
                    <el-form-item label="模型名称" prop="modelName">
                        <el-input v-model.trim="inputForm.modelName"
                                  :disabled="inputType"
                                  placeholder="请输入模型名称(限20字)"
                                  maxlength="20" clearable></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="资源类型" prop="resourceType">
                        <el-select v-model="inputForm.resourceType"
                                   placeholder="请选择资源类型"
                                   :disabled="inputType"
                                   style="width: 100%"
                                   clearable>
                            <el-option
                                    v-for="item in resourceTypeList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="模型类型" prop="modelType">
                        <el-select v-model="inputForm.modelType"
                                   placeholder="请选择模型类型"
                                   :disabled="inputType"
                                   style="width: 100%"
                                   clearable>
                            <el-option
                                    v-for="item in this.$dictUtils.getDictList('model_type')"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="启用状态" prop="modelState">
                        <el-switch
                                v-model="inputForm.modelState"
                                :disabled="inputType"
                                :active-value="'0'"
                                :inactive-value="'1'"
                        >
                        </el-switch>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="标识内容" prop="modelIdentification">
                        <el-input v-model.trim="inputForm.modelIdentification"
                                  :disabled="inputType"
                                  placeholder="请输入标识内容(限50字)"
                                  maxlength="50" clearable></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="text_center" v-if="pageType != 1">
            <el-button size="small" @click="handleClose()">取消</el-button>
            <el-button size="small" type="primary" @click="sureData()" v-no-more-click>保存</el-button>
        </div>
        <div class="text_center" v-else>
            <el-button size="small" @click="handleClose()">关闭</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "modelAdd",
    data() {
        return {
            title: '',
            dialogVisible: false,
            pageType: 0,//0新增 1详情 2修改
            inputType: false,
            inputForm: {
                modelName: '',
                resourceType: '0',
                modelType: '',
                modelState: '0',
                modelIdentification: '',
            },
            rules: {
                modelName: [
                    {required: true, message: '请输入模型名称', trigger: 'blur'},
                ],
                resourceType: [
                    {required: true, message: '请选择资源类型', trigger: 'change'},
                ],
                modelIdentification: [
                    {required: true, message: '请输入标识内容', trigger: 'blur'},
                ],
            },
            resourceTypeList: [
                {
                    value: '0',
                    label: '图片',
                },
            ],
        }
    },

    methods: {
        init(num, row) {
            this.inputType = false
            this.pageType = num
            if (num == 0) {
                this.title = '新增训练模型'
                this.dialogVisible = true
            } else {
                if (num == 1) {
                    this.title = '查看训练模型'
                    this.inputType = true
                } else {
                    this.title = '修改训练模型'
                }
                this.$axios(this.api.dyyg.dyygmodelGetById1 + row.id, {}, 'get').then((res) => {
                    if (res.status) {
                        this.inputForm = res.data
                        this.dialogVisible = true
                    }
                })
            }
        },

        //保存
        sureData() {
            this.$refs.inputForm.validate((valid) => {
                if (valid) {
                    if (this.inputForm.id) {
                        this.$axios(this.api.dyyg.dyygmodelUpdateById1, {
                            ...this.inputForm,
                        }, 'put').then((res) => {
                            if (res.status) {
                                this.$message.success('修改成功')
                                this.$emit('refreshList')
                                this.handleClose()
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    } else {
                        this.$axios(this.api.dyyg.dyygmodelSave1, {
                            ...this.inputForm,
                        }, 'post').then((res) => {
                            if (res.status) {
                                this.$message.success('保存成功')
                                this.$emit('refreshList')
                                this.handleClose()
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

        handleClose() {
            this.$refs.inputForm.resetFields()
            this.inputForm = {
                modelName: '',
                resourceType: '0',
                modelType: '',
                modelState: '0',
                modelIdentification: '',
            }
            this.dialogVisible = false
        },
    },
}
</script>

<style scoped>

</style>